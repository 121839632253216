<template>
  <div class="appeal-container">
    <div>
      <el-form ref="form" :model="form" label-width="80">
        <el-form-item label="申诉主题">
          <el-input v-model="form.appealTheme" placeholder="请输入申诉主题"></el-input>
        </el-form-item>
        <el-form-item label="申诉内容" class="appeal-container-content">
          <el-input type="textarea" v-model="form.content" placeholder="请输入申诉内容"></el-input>
        </el-form-item>
      </el-form>
      <div class="submit-button">
        <el-button type="primary" @click="appealClick">提交申诉</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { appealIn } from '@/api/api'

export default {
  name: 'Appeal',
  data () {
    return {
      form: {
        appealTheme: '',
        content: ''
      }
    }
  },
  methods: {
    appealClick () {
      appealIn({
        method: 'addAppeal',
        content: this.form.content,
        appealTheme: this.form.appealTheme
      }, res => {
        this.$message.info(res.data.message)
        // 如果提交成功 清空表单
        if (res.data.success === true) {
          this.form.content = ''
          this.form.appealTheme = ''
        }
      })
    }
  }
}

</script>

<style>
.appeal-container {
  width: 500px;
  /*display: inline-block;*/
  margin: 0 auto;
  /*padding-left: 300px;*/
  padding-top: 50px;
}

.appeal-container .el-input__inner{
  width: 380px;
  height: 40px;
}

.appeal-container-content .el-textarea__inner {
  display: inline-block;
  width: 380px;
  height: 500px;
}
.appeal-container .el-input,.el-textarea {
  display: inline;
}

.submit-button {
  margin: 90px auto 0;
  text-align: center;
}
</style>
